import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { ErrorHandler } from '@angular/core'
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins


import {CookieService} from "ngx-cookie-service";
import {RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings} from "ng-recaptcha";
import {NgxStripeModule} from "ngx-stripe";
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import {TimeagoModule} from "ngx-timeago";
import {BlankComponent} from "./blank/blank.component";
import {NgxZendeskWebwidgetConfig, NgxZendeskWebwidgetModule} from "ngx-zendesk-webwidget";
import {SharedModule} from "./modules/shared/shared.module";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {SupportModule} from "./modules/support/support.module";
import {GoogleTagManagerModule} from "ngx-google-tag-manager";


Bugsnag.start({ apiKey: '1f7897be7bf0b220d55807fca60bb810' })

export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

registerLocaleData(en);

export class ZendeskConfig extends NgxZendeskWebwidgetConfig {
  accountUrl = 'socialyze.zendesk.com';
  callback(zE: any) {
    // You can call every command you want in here
    // zE('webWidget', 'hide');
    // zE('webWidget', 'show');
  }
}

@NgModule({
  declarations: [AppComponent, BlankComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    NgxStripeModule.forRoot(environment.stripe_public_key),
    NgxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-55936NJ',
    }),
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    TimeagoModule.forRoot(),
    NgbModule,
    FormsModule,
    NzModalModule,
    NzDrawerModule,
    SupportModule
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey
      } as RecaptchaSettings
    },
    CookieService,
    { provide: NZ_I18N, useValue: en_US }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
