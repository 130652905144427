import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd/modal";
import {SupportModalComponent} from "../support-modal/support-modal.component";
import {TicketModalComponent} from "../ticket-modal/ticket-modal.component";

@Component({
  selector: 'app-support-widget-fab',
  templateUrl: './support-widget-fab.component.html',
  styleUrls: ['./support-widget-fab.component.scss']
})
export class SupportWidgetFabComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  currentURL: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showSupportWidget: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  acceptableURLS = [
    {
      wildcard: true,
      url: "/core"
    },
    {
      wildcard: false,
      url: "/auth/login"
    },
    {
      wildcard: false,
      url: "/auth/forgot-password/core"
    },
    {
      wildcard: true,
      url: "/influencers"
    },
    {
      wildcard: true,
      url: "/"
    }
  ]

  constructor(private auth: AuthService, private router: Router, private route:ActivatedRoute, private modal: NzModalService) { }

  ngOnInit(): void {
    this.subscription = this.auth.currentUserType.subscribe((data)=>{
      this.router.events.subscribe((data)=>{
          if(data instanceof NavigationEnd){
            this.currentURL.next(data.urlAfterRedirects);
            this.verifyShowWidget(data.urlAfterRedirects);
          }
      })
    });



  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  verifyShowWidget(currentURL: string){

    this.showSupportWidget.next(true);
    //
    // for(const url of this.acceptableURLS){
    //   if(url.wildcard){
    //     const needle = url.url.split('/');
    //     const haystack = currentURL.split('/');
    //     needle.splice(0,1);
    //     haystack.splice(0,1);
    //
    //     if(haystack.length && needle[0] === haystack[0]){
    //       this.showSupportWidget.next(true);
    //       return;
    //     }
    //   }else{
    //     if(currentURL === url.url){
    //       this.showSupportWidget.next(true);
    //       return;
    //     }
    //   }
    // }
    //
    // this.showSupportWidget.next(false);

  }

  openWidget(){

    const modal = this.modal.create({
      nzContent: SupportModalComponent,
      nzTitle: undefined,
      nzFooter: null,
      nzClassName: 'sc-support-modal',
      nzCentered: true,
      nzCloseIcon: undefined,
      nzComponentParams: {
        currentRoute: this.currentURL.value
      }
    });

    modal.afterClose.subscribe((data)=>{
      if(data){
        this.modal.create({
          nzContent: TicketModalComponent,
          nzTitle: undefined,
          nzFooter: null,
          nzClassName: 'sc-ticket-modal',
          nzCentered: false,
          nzCloseIcon: undefined,
          nzWidth: 800,
          nzComponentParams: {
            currentRoute: this.currentURL.value
          }
        })
      }
    })

  }

}
