import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../services/api.service";
import {SupportService} from "../../../services/support.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {BehaviorSubject} from "rxjs";
import {NzModalRef} from "ng-zorro-antd/modal";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss']
})
export class TicketModalComponent implements OnInit {

  ticketForm: FormGroup;
  $submitting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $submitted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $error: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $result: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  @Input() currentRoute: string;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: 'arial',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'}
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName', 'heading'],
      ['fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode']
    ]
  };

  constructor(private api: ApiService, public auth:AuthService, public fb:FormBuilder, public support: SupportService, private modal: NzModalRef) { }

  ngOnInit(): void {

    if(this.auth.currentUserValue){
      this.ticketForm = this.fb.group({
        route: [this.currentRoute],
        type: [null, Validators.required],
        section: [this.support.retrieveProblemMessage(this.currentRoute), Validators.required],
        description: [null, Validators.required]
      });
    }else{
      this.ticketForm = this.fb.group({
        email: [null, Validators.required],
        name: [null, Validators.required],
        route: [this.currentRoute],
        type: [null, Validators.required],
        section: [this.support.retrieveProblemMessage(this.currentRoute), Validators.required],
        description: [null, Validators.required]
      });
    }

  }

  submitTicket(){

    this.$submitting.next(true);

    if(this.auth.currentTokenValue){
      this.api.submitTicket('submitTicket', this.ticketForm.value).then((res)=>{
        this.$submitted.next(true);
        this.$result.next(res);
      })
    }else{
      this.api.publicPost('submitTicket', this.ticketForm.value).then((res)=>{
        this.$submitted.next(true);
        this.$result.next(res);
      })
    }

  }

  dismissModal(){
    this.modal.close();
  }

}
