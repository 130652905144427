import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {TenantGuard} from "./guards/tenant.guard";
import {SubscribedGuard} from "./guards/subcribed.guard";
import {RetrieveSession} from "./guards/retrieveSession";
import {WhitelabelGuard} from "./guards/whitelabel.guard";
import {ApplyPublicTenant} from "./guards/applypublictenant.guard";
import {InfluencerGuard} from "./guards/influencer.guard";
import {RedirectGuardGuard} from "./guards/redirect-guard.guard";
import {BlankComponent} from "./blank/blank.component";

export const routes: Routes = [

  {
    path: '',
    children: [
      {
        path: 'shortlist',
        loadChildren: () =>
          import('./pages/core/shortlist/shortlist.module').then((m) => m.ShortlistModule),
      },
      {
        path: 'auth',
        loadChildren: () =>
          import('./modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
      },
      {
        path: 'generating',
        loadChildren: () =>
          import('./modules/generating/generating.module').then((m) => m.GeneratingModule),
      },
      {
        path: '',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            canActivate: [TenantGuard],
            children: [
              {
                path: 'setup',
                canActivate: [], // TODO BLOCK OUT CLIENTS AND INFLUENCERS FROM THIS ROUTE
                loadChildren: () =>
                  import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
              },
              {
                path: '',
                canActivate: [], // RUN AUTH GUARD FIRST
                children: [
                  {
                    path: '',
                    canActivate: [SubscribedGuard], // THEN CHECK IF THE SUBSCRIPTION IS ACTIVE
                    children:[
                      {
                        path: '',
                        canActivate: [RetrieveSession], // THEN RETRIEVE THE USER AND SETTINGS
                        loadChildren: () =>
                          import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
                      }
                    ]
                  },
                ]
              },


            ]
          },
        ]
      },
      { path: '**', redirectTo: 'error/404' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
