import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportWidgetFabComponent } from './support-widget-fab/support-widget-fab.component';
import { SupportModalComponent } from './support-modal/support-modal.component';
import {InlineSVGModule} from "ng-inline-svg";
import { TicketModalComponent } from './ticket-modal/ticket-modal.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzSelectModule} from "ng-zorro-antd/select";
import {AngularEditorModule} from "@kolkov/angular-editor";



@NgModule({
  declarations: [
    SupportWidgetFabComponent,
    SupportModalComponent,
    TicketModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    AngularEditorModule,
    InlineSVGModule
  ],
  exports: [
    SupportWidgetFabComponent
  ]
})
export class SupportModule { }
