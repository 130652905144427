<div class="support-fab" (click)="openWidget()">

  <div class="d-inline-flex align-items-center">
    <div class="icon-circle">
      <img src="assets/media/icons/question-mark-draw.svg">
    </div>

    <h3 class="text-grad">Get Support</h3>
  </div>

</div>
