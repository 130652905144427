import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {SupportService} from "../../../services/support.service";
import {NgxZendeskWebwidgetService} from "ngx-zendesk-webwidget";
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent implements OnInit {

  @Input() currentRoute: string;

  constructor(public auth: AuthService, public support: SupportService, private _NgxZendeskWebwidgetService: NgxZendeskWebwidgetService, private modal: NzModalRef) { }

  ngOnInit(): void {
    this._NgxZendeskWebwidgetService.initZendesk();
  }

  openLiveChat(){

    this._NgxZendeskWebwidgetService.zE('messenger', 'open');
    this.modal.close(false);

  }

  openTicketForm(){

    this.modal.close(true);

  }

}
