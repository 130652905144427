export class WhiteLabelSettings{
  ui_logo: string;
  graph_logo: string;
  login_image: string;
  primary_colour: string;
  tertiary_colour: string;
  secondary_colour: string;
  contrast_text_colour: string;
  graph_primary?: string;
  graph_secondary?: string;
  graph_tertiary?: string;
  menu_color?: string;
  navigation_color?: string;
}
