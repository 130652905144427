export const environment = {
  production: false,
  appVersion: 'v1',
  host_domain: 'https://api.socialyze.co',
  tenant_domain: 'api.socialyze.co',
  media_domain: 'mediastore.socialyze.co',
  client_url: 'https://clients.socialyze.co',
  influencer_url: 'https://influencers.socialyze.co',
  postcodeLookup: 'api.postcodes.io/postcodes/',
  httpProtocol: 'https://',
  recaptcha: {
    siteKey: '6LepOIcdAAAAAHX0OLcR-k6dAd26uD7PG5CQN1l_',
  },
  googleMapsKey: 'AIzaSyD33nWk9C_PSlHe1EZkEI_M1Mi-CGCgBDw',
  stripe_public_key: 'pk_live_51KX4BnB4rh0J9B4tUG1bJoJdHXCuceOgy7NbVZjVNxlddOFIDXSEu70rhNaNyxs8ITJSgr0s7TgD95oLFUHvlxk800AxpA1rLM',
  clientIDs: {
    core: 1,
    influencer: 2,
    client: 3
  },
  currencyCodes: [
    {
      code: 'gbp',
      symbol: '£ (GBP)'
    },
    {
      code: 'eur',
      symbol: '€ (EUR)'
    },
    {
      code: 'aud',
      symbol: '$ (AUD)'
    },
    {
      code: 'usd',
      symbol: '$ (USD)'
    }
  ],
  plans:{
    enterprise: "price_1L2tvfB4rh0J9B4tXbNxssQg",
    premium: "price_1L2twvB4rh0J9B4tPUCQxiKK",
    standard: "price_1L2txWB4rh0J9B4tbNRAhNIQ"
  }
};
