import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";
import {TokenStore} from "../modules/auth/models/TokenStore";
import {Subscription} from "rxjs";
import {TenantService} from "./tenant.service";
import {TokenCounterComponent} from "../_metronic/layout/components/token-counter/token-counter.component";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements  OnDestroy{

  authenticatedOptions: any;
  tokenSubscription: Subscription;

  test = {"token_type":"Bearer","expires_in":31536000,"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNDkxNzE4OGJkYjlmZGEzN2FiNDMzYmYyMjZhYjE3NjUzMzg0YzA3MTMxOTQyZWI4OTdhM2VmY2Y5Yjc0ZTUyYmQ0MjM2MTBhOGEyYWNiZGIiLCJpYXQiOjE2NzAzMzc1OTkuMjUyMDk3LCJuYmYiOjE2NzAzMzc1OTkuMjUyMDk5LCJleHAiOjE3MDE4NzM1OTkuMjI2MzY4LCJzdWIiOiIxMiIsInNjb3BlcyI6W119.DHn8fkTMaLRPJ7jYfIHe3Oda45XVRLbwcrD2ctRNR_Viygbi5Kz8BP2dO9wH5PgV_C_MRJBM_x-pIZ_qnDxlsV51dF7iFIeBq9zUrzVAOCeDH5fm43NrLFKZldNkxJYySCsvGugoiegTurzoduJm1HKAZgtarH8naEoZzmHFL541kUQCnmwwV8CjVFa1wtX_eW_x7_KIjgUn4_1HHsSZiinNM3P-RT9y28CNVrE8n6YBUKqt7wS4qMFw7Pvi37pvwFHLq_uLRK41ObMxEH_vpYE48r3cW99apIjB7yMCDh-hHkXC6zlmurCjXk5V6NWekklDErHnbeIEm2PMi0jvUsrF63WPTXYa9ApyDSOEQeuenX_Tex3qq6T4gobQarW8Kq1sheJYAlscO15jvNErjeiAz8toMQz0jnj0n0Ldr3fixN-aSsz9eUjEi6tP8gVnM8HE-KvXyNPbLQpQPfi4_vt4qjEWgRlq5oe51oEoKWYZvLozAsRXSJDad-OWKozxmnHqTmrz55tfQI-ZlzyUEnuJ0vgrMWrJbXSxIEmYrhov4VJFLiflc6oJNmdbgI8Q-pA6HmpMdlNX-8pSG4weulkuRBmG9rm5-AFsS4eejUw5u6K5Rz8OE8kU4KDvlG2-nuIXaDU_7V7Wz0j6avhfwFgrooi0yQYqcHt50wC9Gcg","refresh_token":"def5020032d0290139a6436ac0b6936e189e3968698a1b5f4b317f0d2dee2b9173a56a0d1b32b2ccfeeab07030c1dd77f8ca5a6a5848bf35c21db8143c86f2c2935c7ac8ac1ab643abaf4552d64c37c9edd81d13df31d2a34a6c4760d2716a9cd1352fa590ed9154d7f87d8538f64e3c483757305071d3e1f6c743ffdca634c55df9f3e65419c5845479517a839b55675e89227846238342fbec9c2b2803964de043ecba861706ebe61fa3c03ebcd4d14b6172345d4a1a47a7e42f99b44f9607cc0930cd504b0f703ca333cb761183f53c1a2f6b2ff95122ed76965b61960a98f67dde2e7cb44c7f3e64785b4fc48085d9ee0cc93752ac6b590e2aa24f4a43f8adab2e054fd382a1357fe3d58adbf50c1f03bf78b3d944e802f9317ba719a38908dcfc380198550c74fdc53cca3d80dbfc6d3daf3d0fe4fdac899b7841724b9982a16ee9a0040b89f0954c49d467b53ea1868af77362537cd85336673a5842a43334","expires_at":"2023-12-06T14:38:19.834Z"};

  constructor(public http: HttpClient, private auth: AuthService) {
    this.tokenSubscription = this.auth.currentToken$.subscribe((data: TokenStore | null) => {
      if(data){
        this.authenticatedOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.access_token
          }),
        };
      }
    });
  }

  checkPublicSubscriptionStatus(userType: string): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/status',  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  refreshTokenBalance(){

    this.getPackageAndBalance('core').then((res)=>{
      this.auth.currentTokenBalance.next(res.Remaining);
    })

  }

  getPackageAndBalance(userType: string): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/balance',  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  checkOwnerSubscriptionStatus(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/subscription',  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  createSetupIntent(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/setupIntent', {},  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  updatePaymentMethod(methodID: string): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/saveMethod', {
          paymentMethod: methodID
        },  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }



  updatePaymentMethodSubscribe(methodID: string, planID: string): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/saveMethodAndSubscribe', {
          paymentMethod: methodID,
          plan: planID
        },  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  getCancelationReasons(): Promise<any> {
    return new Promise((resolve, reject) => {

      this.http
        .get(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/subscription/reasons',  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });
  }

  endTrialEarly(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/endTrial', {},  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  cancelTrial(): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/subscription/cancelTrial', {},  this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  cancelSubscription(reason: any): Promise<any>{

    return new Promise((resolve, reject) => {

      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/core/settings/payment/subscription/cancel', {
          'cancellation_reason': reason
        }, this.auth.tenant.appendAuthenticatedHeader(this.authenticatedOptions))
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );

    });

  }

  ngOnDestroy(): void {
    this.tokenSubscription.unsubscribe();
  }

}
