<ng-container *ngIf="($submitted | async)">

  <h1 class="mb-6">Thank you</h1>
  <div class="">
    <p class="mb-2">Your support request has been recieved.</p>

    <h3>Your ticket reference ID is {{($result | async)?.ticket.id}}</h3>
  </div>

  <div class="d-flex align-items-end mt-6">

    <div class="ms-auto">
      <button class="btn btn-theme btn-sm" type="button" (click)="dismissModal()">Return to Socialyze</button>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="!($submitted | async)">
  <form [formGroup]="ticketForm" (submit)="submitTicket()">

    <h1 class="mb-6">Submit a support ticket</h1>
    <div class="">
      <p class="mb-2">Use our support ticket system to report a problem or request some assistance. A member of our support team will come back to you as soon as possible.</p>
    </div>

    <div class="alert alert-warning" *ngIf="auth.currentUserValue">
      <h4 class="mb-2"><strong>Submitting ticket as:</strong></h4>
      <p class="mb-0">{{auth.currentUserValue.name}} - {{auth.currentUserValue.email}}</p>
    </div>

    <ng-container *ngIf="!auth.currentUserValue">
      <h2 class="mt-6">Introduce yourself</h2>

      <div class="row">
        <div class="col-md-6">

          <div class="fv-row mb-5">
            <label class="form-label fw-bolder fs-6 text-dark mb-4">Full Name*</label>
            <input formControlName="name" type="text" class="form-control">
          </div>

        </div>
        <div class="col-md-6">

          <div class="fv-row mb-5">
            <label class="form-label fw-bolder fs-6 text-dark mb-4">Email*</label>
            <input formControlName="email" type="text" class="form-control">
          </div>

        </div>
      </div>
    </ng-container>


    <h2 class="mt-6">How can we help?</h2>

    <div class="row">

      <div class="col-md-6">

        <div class="single-option selected" [class.selected]="ticketForm.controls['type'].value === 'bug'" (click)="ticketForm.controls['type'].setValue('bug')">

          <div class="d-flex">
            <h4>Report a bug</h4>
            <div class="bullet-select"></div>
          </div>
          <p class="mb-0">Something not quite working as it should? <br/> Let us know!</p>
        </div>

      </div>
      <div class="col-md-6 mt-4 mt-md-0">

        <div class="single-option" [class.selected]="ticketForm.controls['type'].value === 'help'" (click)="ticketForm.controls['type'].setValue('help')">

          <div class="d-flex">
            <h4>Ask for help</h4>
            <div class="bullet-select"></div>
          </div>

          <p class="mb-0">Not sure how to do something? <br/> Ask us for guidance.</p>

        </div>

      </div>

    </div>

    <ng-container *ngIf="ticketForm.controls['type'].value">
      <hr class="my-5">
      <label class="form-label fw-bolder text-dark fs-6" *ngIf="ticketForm.controls['type'].value === 'bug'">What does the bug relate to?</label>
      <label class="form-label fw-bolder text-dark fs-6" *ngIf="ticketForm.controls['type'].value !== 'bug'">What can we help you with?</label>
      <nz-select nzSize="large" class="form-control" formControlName="section">
        <nz-option [nzValue]="option.problem_message" [nzLabel]="option.problem_message | titlecase" *ngFor="let option of support.supportFunctions"></nz-option>
        <nz-option nzValue="Other" nzLabel="Something Else"></nz-option>
      </nz-select>


      <div class="fv-row mb-5 mt-5">
        <label class="form-label fw-bolder fs-6 text-dark mb-4" *ngIf="ticketForm.controls['type'].value === 'bug'">Please describe the bug in as much detail as possible</label>
        <label class="form-label fw-bolder fs-6 text-dark mb-4" *ngIf="ticketForm.controls['type'].value === 'help'">Write out your message for our support team here</label>
        <angular-editor formControlName="description" [config]="editorConfig"></angular-editor>
      </div>
      <div class="alert alert-warning" *ngIf="ticketForm.controls['type'].value === 'bug'"><strong>The more detail you can provide, the quicker we can resolve the issue. Any steps you can provide to reproduce the issue would be helpful.</strong></div>

    </ng-container>

    <div class="d-flex align-items-end mt-6">

      <div class="ms-auto">
        <button class="btn btn-light btn-sm me-2" type="button" [hidden]="($submitting | async)" (click)="dismissModal()">Cancel</button>
        <button class="btn btn-theme btn-sm" type="submit" [disabled]="($submitting | async) || !ticketForm.valid">

          <ng-container *ngIf="!($submitting | async)">Submit Support Ticket</ng-container>
          <ng-container *ngIf="($submitting | async)">
            <span class="indicator-progress" [style.display]="'block'">
              Please wait...{{ " " }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </ng-container>

        </button>
      </div>
    </div>



  </form>

</ng-container>
