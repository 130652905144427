import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router,
} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {TokenStore} from "../modules/auth/models/TokenStore";
import {Observable} from "rxjs";
import {DateTime} from "luxon";
import {SubscriptionService} from "../services/subscription.service";

@Injectable({providedIn: 'root'})
export class SubscribedGuard implements CanActivate {
  constructor(private subscriptionService: SubscriptionService, private auth:AuthService, public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise(async (resolve) => {

      console.log('Subscribe Guard Started');

      const userType = this.auth.currentUserType.value;

      let redirect = '/setup/subscribe';
      if(userType === 'influencer' || userType === 'client' ){
        let redirect = '/setup/unavailable';
      }

      const subscription = this.auth.currentPaymentStatusSubject.value;

      console.log('===== subscription debug in guard');
      console.log(subscription);


      //resolve(true);

      if(!subscription){

        console.log("no subscription");

        // NO SUBSCRIPTION SUBJECT SET

        // CHECK WITH THE SERVER ABOUT THE SUBSCRIPTION STATUS
        this.subscriptionService.checkPublicSubscriptionStatus(userType).then((SubscriptionStatus) =>{
          // THERE IS AN EXISTING SUBSCRIPTION
          this.auth.currentPaymentStatusSubject.next(SubscriptionStatus);

          console.log(SubscriptionStatus)

          if(this.canAccess()){
            console.log("ALLOWED ACCESS");

            // THEY ARE ALLOWED TO ACCESS THE SOFTWARE, NOW LETS GET THEIR TOKEN BALANCE

            if(userType === 'core'){

              this.subscriptionService.getPackageAndBalance('core').then((res)=>{
                this.auth.currentTokenBalance.next(res.Remaining);
                this.auth.currentPackage.next(res.Package);
                this.auth.currentFeatures.next(res.MetaData);
              })

            }

            resolve(true);

          }else{
            // SUBSCRIPTION IS INVALID
            console.log(redirect);
            //this.router.navigateByUrl(redirect)
            this.router.navigateByUrl('/setup/subscribe');
            resolve(true);
          }

        }).catch((SubscriptionError)=>{

          console.log("THERE IS A SUB ERROR");
          console.log(SubscriptionError);
          // TODO ADD ADDITIONAL LOGIC HERE TO HANDLE GENERIC ERRORS AS CURRENTLY A FAIL WILL MAKE IT LOOK
          //  LIKE THE USER ISN'T SUBSCRIBED

          // // IF the user is on a trial then let them pass..
          this.subscriptionService.checkOwnerSubscriptionStatus().then((SubscriptionResult)=> {

            console.log(SubscriptionResult)
            if(SubscriptionResult.StatusFlags.OnTrial == true) {
              this.auth.currentPaymentStatusSubject.next(SubscriptionResult);
              console.log("This user is on a trial")
              //this.router.navigateByUrl(redirect)
              // this.router.navigateByUrl('/influencers/search');
              //this.router.navigateByUrl(redirect)
              resolve(true);
            } else {
              this.auth.currentPaymentStatusSubject.next(null);
              this.router.navigateByUrl(redirect)
              resolve(false);
            }
          })
          //
          // this.auth.currentPaymentStatusSubject.next(null);
          // this.router.navigateByUrl(redirect)
          // resolve(false);

        });

      }else{

        // SUBSCRIPTION HAS ALREADY BEEN CHECKED AND STORED IN SUBJECT, DON'T REPEAT IT TO REDUCE MEMORY / CPU LOAD
        resolve(true);

      }

    });
  }

  canAccess(): boolean {

    const subscription = this.auth.currentPaymentStatusSubject.value;

    // If you are subscribed then return true
    if(subscription?.Subscribed) {
      return true
    } else {
      // @ts-ignore
      return subscription.StatusFlags.OnTrial
    }
    // if(!subscription){
    //   return false;
    // }else{
    //
    //   return subscription.StatusFlags.OnTrial ||
    //     subscription.StatusFlags.DueToEnd ||
    //     subscription.StatusFlags.Active;
    //
    // }

  }

}
