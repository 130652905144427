import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {TenantService} from "./tenant.service";
import {TokenStore} from "../modules/auth/models/TokenStore";
import { DateTime } from "luxon";
import {SubscriptionStatus} from "../models/SubscriptionStatus";
import {CurrentUser} from "../models/CurrentUser";

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {

  currentUser$: Observable<CurrentUser | any |  null>;
  currentTenantRole$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isLoading$: Observable<boolean>;
  currentPaymentStatus$: Observable<SubscriptionStatus | null>;
  currentUserSubject: BehaviorSubject<CurrentUser | any | null> =  new BehaviorSubject<CurrentUser | any | null>(null);
  currentPaymentStatusSubject: BehaviorSubject<SubscriptionStatus | null> = new BehaviorSubject<any>(undefined);
  currentToken$: Observable<TokenStore | null>;
  currentTokenSubject: BehaviorSubject<TokenStore | null> = new BehaviorSubject<TokenStore | null>(null);
  currentPackage: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentFeatures: BehaviorSubject<any> = new BehaviorSubject<any>({});
  currentUserType: BehaviorSubject<string> =  new BehaviorSubject<string>('');
  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentTokenBalance: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  authenticatedOptions: any;
  tokenSubscription: Subscription;


  redirects = {
    core: '/',
    influencer: '/influencers/campaigns',
    client: '/clients/campaigns',
  }

  constructor(
    private http: HttpClient,
    public cookieService: CookieService,
    public tenant: TenantService,
    private router: Router
  ) {
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentToken$ = this.currentTokenSubject.asObservable();
    this.currentPaymentStatus$ = this.currentPaymentStatusSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();

    this.tokenSubscription = this.currentToken$.subscribe((data: TokenStore | null) => {
      if(data){
        this.authenticatedOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + data.access_token
          }),
        };
      }
    });
  }

  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  get currentTenantRole(): string | null {
    return this.currentTenantRole$.value;
  }

  set currentTenantRole(role: string | null) {
    this.currentTenantRole$.next(role);
  }

  get currentTokenValue(): any {
    return this.currentTokenSubject.value;
  }

  set currentTokenValue(user: any) {
    this.currentTokenSubject.next(user);
  }

  // public methods
  login(loginData: any): Promise<any> {

    loginData.client_id = 1;
    loginData.grant_type = 'password';
    loginData.scope = '';

    this.isLoadingSubject.next(true);
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/oauth/token', loginData, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }),
        })
        .subscribe(
          (res) => {
            this.isLoadingSubject.next(false);
            resolve(res);
          },
          err => {
            this.isLoadingSubject.next(false);
            reject(err);
          },
        );
    });

  }

  requestNewPassword(data: any, type: 'core' | 'influencer' | 'client' = 'core'){

    data.type = type;
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/reset/request', data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            console.log(err);
            reject(err);
          },
        );
    });

  }

  checkResetToken(data: any){

      return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/reset/code', data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  changePasswordReset(data: any){

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/api/reset/reset', data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  renewToken(data: TokenStore, type : 'core' | 'influencer' | 'client' = 'core'): Promise<any> {

    const TokenRequestData = {
      grant_type: 'refresh_token',
      refresh_token : data.refresh_token,
      client_id: environment.clientIDs[type],
      scope: ''
    }

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.httpProtocol + environment.tenant_domain + '/oauth/token', TokenRequestData, this.tenant.appendTenantHeader())
        .subscribe(
          (res) => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  logout() {

    this.destroySession();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  destroySession() {
    this.cookieService.delete('socialyze_token_data');

    // INFLUENCERS AND CLIENTS DON'T NEED TO DELETE THIS AS THEY ONLY LOG INTO ONE PLACE
    if(this.userType === 'core'){
      this.cookieService.delete('socialyze_tenant');
      this.cookieService.delete('socialyze_domain');
      this.tenant.currentTenantSubject.next(null);
      this.tenant.currentDomainSubject.next(null);
    }

    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
    this.currentPaymentStatusSubject.next(null);

  }



  ngOnDestroy() {
  }

  public saveTokenToStorage(data: TokenStore): TokenStore{
    data.expires_at = DateTime.now().plus({seconds: (data.expires_in - 100)}).toJSDate();
    this.cookieService.set('socialyze_token_data', JSON.stringify(data), {path: '/', sameSite: 'Lax'});
    return data;
  }

  public saveUserType(data: 'core' | 'client' | 'influencer'){
    this.cookieService.set('socialyze_type', data, {path: '/', sameSite: 'Lax'});
    return data;
  }

  get userType(): 'core' | 'client' | 'influencer'{
    return this.cookieService.get('socialyze_type') as 'core' | 'client' | 'influencer';
  }

  set userType(data: 'core' | 'client' | 'influencer'){
    this.cookieService.set('socialyze_type', data, {path: '/', sameSite: 'Lax'});
  }

  public retrieveTokenFromStorage(): TokenStore | null{
    if(this.cookieService.check('socialyze_token_data')){
      return JSON.parse(this.cookieService.get('socialyze_token_data'))
    }else{
      return null;
    }
  }

  containsCustomDomain(data: string){

    return data.indexOf('.')> -1

  }


}
