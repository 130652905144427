import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import {TenantService} from "./tenant.service";
import {TokenStore} from "../modules/auth/models/TokenStore";
import { DateTime } from "luxon";
import {SubscriptionStatus} from "../models/SubscriptionStatus";

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  currentSettings: Observable<any>;
  currentSettingsSubject: BehaviorSubject<any>;

  get settings(): any {
    return this.currentSettingsSubject.value;
  }

  set settings(user: any) {
    this.currentSettingsSubject.next(user);
  }

  constructor(
    public tenant: TenantService,
  ) {
    this.currentSettingsSubject = new BehaviorSubject<any>(null);
    this.currentSettings = this.currentSettingsSubject.asObservable();
  }


}
