<h1 class="mb-6">Hello.</h1>
<div class="">
  <h4 class="mb-2"><strong>It looks like you need help with {{support.retrieveProblemMessage(currentRoute)}}.</strong></h4>
  <p>{{support.retrieveSolutionMessage(currentRoute)}}</p>
</div>


<h4>You can reach us via:</h4>

<div class="support-methods">

<a class="single-support-method" (click)="openLiveChat()">
  <span
    [setSVGAttributes]="{style: 'width:40px; height:40px;'}"
    [inlineSVG]="'./assets/media/icons/custom/live-chat.svg'"
    class="svg-icon me-4"
  ></span>
  <div>
    <h4>Live Chat</h4>
    <p>Ideal for when you need immediate advice</p>
  </div>

</a>

<a class="single-support-method" (click)="openTicketForm()">
    <span
      [setSVGAttributes]="{style: 'width:40px; height:40px;'}"
      [inlineSVG]="'./assets/media/icons/custom/support-ticket.svg'"
      class="svg-icon me-4"
    ></span>
  <div>
    <h4>Open a ticket</h4>
    <p>To provide us with a detailed report of an issue</p>
  </div>

</a>

<a href="mailto:support@socialyze.co" class="single-support-method">
  <span
    [setSVGAttributes]="{style: 'width:40px; height:35px;'}"
    [inlineSVG]="'./assets/media/icons/custom/mail.svg'"
    class="svg-icon me-4"
  ></span>
  <div>
    <h4>Email Us</h4>
    <p>support@socialyze.co</p>
  </div>

</a>

</div>

